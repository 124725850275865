<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state['app'].userData.name }}
            </p>
            <span class="user-status">{{ $store.state['app'].userData.department_info ? $store.state['app'].userData.department_info.name : null }}</span>
          </div>
          <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          /> -->
          <!-- :src="require('@/assets/images/avatars/13-small.png')" -->
          <div class="user-avater">
            <b-avatar
              size="40"
              variant="light-primary"
              :src=" $store.state['app'].userData ? `${$store.state['app'].userData.image}` : null"
              class="badge-minimal"
            >
              <!-- <template #badge></template> -->
              <feather-icon
                v-if="!$store.state['app'].userData"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>

            <div class="user-state-badge">
              <div
                class="state-dot dot-green"
                :class="{ 'dot-green': windowVisibilityState === 'visible', 'dot-yellow': windowVisibilityState === 'hidden' }"
              />
              <!-- 1: 'dot-gray', 2: 'dot-green', 3: 'dot-yellow', -->
              <!-- {{ windowVisibilityState }} -->
            </div>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="linkUserProfile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>個人資料</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="changePassword"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>重設密碼</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>通知訊息</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>登出</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <user-modal-password
      ref="changePasswordModal"
    />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import userModalPassword from './Modal/password-chg-modal/PasswordChgModal.vue'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    userModalPassword,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    windowVisibilityState() {
      return this.$store.state.app.windowVisibilityState
    },
  },
  methods: {
    linkUserProfile() {
      this.$router.push({ name: 'admin-user-view', params: { id: this.$store.state.app.userData.id } })
    },
    changePassword() {
      this.$refs.changePasswordModal.getData()
    },
    logout() {
      this.$swal({
        title: '登出',
        text: '你是否要登出後台管理系統',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('api/logout')
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          this.$router.push({ name: 'page-login' })
        }
      })
    },
  },
}
</script>

<style lang="scss" scope>
.mask {
  background-color: rgba(155, 155, 155, 0.5);
  z-index: 99999;
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .mask-center {
    position: absolute;
    top:50%;
    left: 50%;
  }
}

.user-avater {
  position: relative;
  .user-state-badge {
    position: absolute;
    bottom: 0;
    right: 0;

  }
}

.state-dot {
  width: 10px;
  height: 10px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  line-height:20px;
  vertical-align:middle;
  text-align:center;
}

.dot-gray {
  background:radial-gradient(circle closest-side at center,#c4c4c4,#767676);
}

.dot-green {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
  box-shadow: 0 0 16px 4px #66ff6688;
}

.dot-yellow {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
  box-shadow: 0 0 16px 4px #f6e9ad88;
}
</style>
